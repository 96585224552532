import { cloudinary } from '@constants'
import { forwardRef } from 'react'
import Img from './StyledImg'
import withCloudinary from './withCloudinary'

// eslint-disable-next-line react/prop-types
const CloudinaryImage = forwardRef(({ url, setRef, alt, className, responsive, lqProgressive }, ref) => {
  const imgProps = { alt, className, responsive, lqProgressive }
  return <Img src={cloudinary.throughCloudfront(url)} ref={ref} setRef={setRef} loaded {...imgProps} />
})
CloudinaryImage.displayName = 'CloudinaryImage'

export default withCloudinary(CloudinaryImage)
