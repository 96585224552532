import { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import shortid from 'shortid'
import cssClasses from '../../constants/cssClasses'
import px2rem from '../../utils/px2rem'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'
import { Container } from '@mch-group/uikit-components'

const mID = 'rm-sector-exhibitor-list'
const HTTP_URL = 'http://'

const ExhibitorURL = (item) => (
  <a target='_blank' href={item.url} className={item.className} rel='noopener'>
    {item.name}
  </a>
)

const splitGalleriesAndArtists = (item) => {
  const galleryGroups = item.split(',\n')
  const galleries = []
  let artists = ''
  //  pick up gallery and artists string
  const galleryArtistStr = galleryGroups.pop()

  galleryGroups.forEach((group) => {
    const galleryProperties = group.split('\n')
    const newGallery = { name: galleryProperties[0], url: galleryProperties[1] }
    if (newGallery.url && newGallery.url.indexOf(HTTP_URL) === -1) {
      newGallery.url = HTTP_URL + newGallery.url
    }
    galleries.push(newGallery)
  })

  if (galleryArtistStr) {
    const galleryArtistArray = galleryArtistStr.split('\n')
    const newGallery = { name: galleryArtistArray.shift(), url: galleryArtistArray.shift() }
    if (newGallery.url && newGallery.url.indexOf(HTTP_URL) === -1) {
      newGallery.url = HTTP_URL + newGallery.url
    }
    galleries.push(newGallery)
    if (galleryArtistArray.length > 0) {
      artists = galleryArtistArray.join(', ')
    }
  }
  return { galleries, artists }
}

const groupExhibitors = (props) => {
  const aggregatedData = []
  const exhibitors = props.sel_grp1_exhibitors.split('\n\n')
  exhibitors.forEach((item) => {
    const jExhibitor = {}
    const row = item.split(/\[(.?)\]\n/)
    if (row[1]) {
      const category = {}
      category.groupLabel = row[1]
      row.splice(0, 2)
      aggregatedData.push(category)
    }

    const exhibitorObj = splitGalleriesAndArtists(row[0])
    jExhibitor.galleries = exhibitorObj.galleries
    jExhibitor.artistList = exhibitorObj.artists
    aggregatedData.push(jExhibitor)
  })
  return aggregatedData
}

const RmCategoryItem = (item) => (
  <ItemCell>
    <CategoryTitle>{item.groupLabel}</CategoryTitle>
  </ItemCell>
)

const ExhibitorLine = (item) =>
  item.artistList ? (
    <span className={`${mID}__content`}>
      {item.artistList} |
      {item.galleries.map((url, index) => (
        <Fragment key={shortid.generate()}>
          <ExhibitorURL {...url} className={`${mID}__url_normal`} />
          {index < item.galleries.length - 1 ? ', ' : ''}
        </Fragment>
      ))}
    </span>
  ) : (
    <span>
      {item.galleries.map((url) => (
        <ExhibitorURL {...url} className={`${mID}__content`} key={shortid.generate()} />
      ))}
    </span>
  )

const RmExhibitorItem = (item) => (
  <ItemCell>
    <div>
      <ExhibitorLine {...item} />
    </div>
  </ItemCell>
)

const SectorExhibitorList = ({ className, ...props }) => (
  <Container id={props.id} className={`${className} ${cssClasses.CMS_MODULE} px-5 px-lg-8 px-md-7`}>
    {props.sel_grp1_title && <GroupTitle className='mb-9 fw-bold'>{props.sel_grp1_title}</GroupTitle>}
    <div className={`${mID}__list`}>
      {props.sel_grp1_exhibitors &&
        groupExhibitors(props).map((item) =>
          item.groupLabel ? (
            <RmCategoryItem {...item} key={shortid.generate()} />
          ) : (
            <RmExhibitorItem {...item} key={shortid.generate()} />
          )
        )}
    </div>
  </Container>
)

SectorExhibitorList.propTypes = {
  id: PropTypes.number,
  className: PropTypes.string,
  sel_grp1_title: PropTypes.string,
  sel_grp1_exhibitors: PropTypes.string
}

const RmSectorExhibitorListStyled = styled(SectorExhibitorList)`
  h2.ui.header {
    margin-bottom: ${px2rem(60)};
  }

  .${mID} {
    &__list {
      & > .item:first-child {
        border-top: none;
      }

      & > .item:last-child {
        border-bottom: 1px solid var(--bs-mch-gray-500);
        padding-bottom: ${px2rem(27)};
      }

      & > .item {
        border-top: 1px solid var(--bs-mch-gray-500);
        padding: ${px2rem(27)} 0;
      }

      .ui.header {
        color: inherit;
      }
    }

    &__content {
      font-weight: ${tokens.fontWeights.artBaselHeadline0.value};
    }

    &__url_normal {
      font-weight: ${tokens.fontWeights.artBaselText1.value};
      margin-left: ${px2rem(4)};
    }
  }
`

const ItemCell = styled.div`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #404040;
  padding: 27px 0 27px 0;
`

const CategoryTitle = styled.div`
  font-size: 30px;
  text-align: start;
  font-weight: bold;
`

const GroupTitle = styled.div`
  font-size: 36px;
`

export default RmSectorExhibitorListStyled
