import { string, number, object } from 'prop-types'
import { Container, Col } from '@mch-group/uikit-components'
import styled from 'styled-components'

import cssClasses from '@constants/cssClasses'
import CloudinaryImage from '@components/Image/CloudinaryImage'

const ImageModule = ({ image_module_image: imageUrl, image_module_caption: caption }) => (
  <StyledContainer className={cssClasses.CMS_MODULE}>
    <Container className='ps-8 pe-6'>
      <Col xs={12} md={10}>
        <figure>
          <CloudinaryImage publicId={imageUrl} crop='fill' alt={caption} responsive dpr='auto' f='auto' q='auto' />
          {caption && <figcaption>{caption}</figcaption>}
        </figure>
      </Col>
    </Container>
  </StyledContainer>
)

const SectionContainer = (props) => <Container as='section' {...props} />

const StyledContainer = styled(SectionContainer)`
  .col:not(.row) {
    padding-top: 0;
  }

  figure {
    margin: 0 !important;

    figcaption {
      color: var(--bs-mch-gray-400);
      display: inline-block;
    }
  }
`

ImageModule.propTypes = {
  id: number,
  image_module_image: string,
  image_module_caption: string,
  userMe: object
}

export default ImageModule
